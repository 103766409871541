<template>
    <div class="ele-body">
        <el-card shadow="never">
            <!-- 搜索表单 -->
            <el-form :model="table.where" class="ele-form-search d-flexspabet mt-20"
                @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
                <div>
                    <el-button @click="showEdit=true" class="ele-btn-icon addbtn" size="small"
                        v-if="permission.includes('sys:serviceDescription:add')">添加服务费说明</el-button>
                </div>
                <div class="d-flex">
                    <el-form-item label="显示版：" label-width="70px" class="w-200">
                        <el-select v-model="table.where.type" placeholder="请选择" clearable class="ele-fluid">
                            <!--                  <el-option label="全部" value="10"></el-option>-->
                            <!-- <el-option label="全平台" value="111"></el-option> -->
                            <el-option label="跑腿版" value="1" />
                            <el-option label="司机版" value="2" />
                            <el-option label="商家版-超市" value="3" />
                            <el-option label="商家版-叫餐" value="4" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                            class="ele-btn-icon ml-20">查询</el-button>
                        <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
                    </el-form-item>
                </div>
            </el-form>
            <!--数据表格-->
            <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                highlight-current-row :stripe=true>
                <template slot-scope="{index}">
                    <el-table-column type="selection" width="45" align="center" fixed="left" />
                    <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
                        show-overflow-tooltip />
                    <el-table-column prop="type_name" label="显示版" show-overflow-tooltip min-width="250" />
                    <el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="250" />
                    <el-table-column label="更新时间" show-overflow-tooltip min-width="160">
                        <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
                    </el-table-column>
                    <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
                        <template slot-scope="{row}">
                            <el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false"
                                v-if="permission.includes('sys:serviceDescription:edit')">编辑</el-link>

                            <el-link @click=" remove(row)" slot="reference" icon="el-icon-delete" type="danger"
                                :underline="false" v-if="permission.includes('sys:serviceDescription:delete')">删除
                            </el-link>
                        </template>
                    </el-table-column>
                </template>
            </ele-data-table>
        </el-card>
        <!-- 编辑弹窗 -->

        <el-dialog v-dialogDrag :title="editForm.id?'修改服务费说明':'添加服务费说明'" :visible.sync="showEdit" width="900px"
            @closed="editForm={}" :destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
            <el-card shadow="never">
                <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">

                    <el-form-item label="显示版：" prop="type">
                        <el-select v-model="editForm.type" calss="selectStyle" :disabled="editForm.id?true:false"
                            clearable>
                            <!--                  <el-option value="0"  label="全平台"></el-option>-->
                            <el-option v-for="option in accordList" :value="option.id" :key="option.id"
                                :label="option.name"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属区域：" v-if="editForm.id">
                        <el-select v-model="editForm.pname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择省"
                            disabled v-if="editForm.pname">
                            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                                :label="option.name"></el-option>
                        </el-select>
                        <el-select v-model="editForm.cname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择市"
                            disabled v-if="editForm.cname">
                            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                                :label="option.name"></el-option>
                        </el-select>
                        <el-select v-model="editForm.aname" class="selectStyle mb-20" clearable
                            :class="(editForm.pname && editForm.cname)?'mr-10':''" placeholder="请选择区/县" disabled
                            v-if="editForm.aname">
                            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                                :label="option.name"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属区域：" v-if="!editForm.id && editForm.type!=0">
                        <el-select v-model="editForm.pid" class="selectStyle mr-10 mb-20" clearable
                            @change="handleChangeProv1(editForm.pid)" calss="selectStyle">
                            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
                                :label="option.name"></el-option>
                        </el-select>
                        <el-select v-model="editForm.cid" class="selectStyle mr-10 mb-20" clearable
                            @change="handleChangeCity1(editForm.cid)" calss="selectStyle">
                            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
                                :label="option.name"></el-option>
                        </el-select>
                        <el-select v-model="editForm.aid" class="selectStyle mr-10 mb-20" clearable
                            @change="$forceUpdate()" calss="selectStyle">
                            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
                                :label="option.name"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="图文内容：">
                        <tinymce-editor v-model="editForm.content" :init="editContent" />
                    </el-form-item>
                </el-form>
            </el-card>
            <div slot="footer">
                <el-button @click="showEdit=false">取消</el-button>
                <el-button type="primary" @click="save">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import TinymceEditor from '@/components/TinymceEditor'
import { mapGetters } from "vuex";
export default {
    name: "SysNotice",
    components: { TinymceEditor },
    data() {
        return {
            table: { url: '/notice/servince_introduce', where: {} },  // 表格配置
            choose: [],  // 表格选中数据
            showEdit: false,  // 是否显示表单弹窗
            editForm: { source: 1, status: 1, is_top: 2 },  // 表单数据
            sideType: '',
            accordList: [
                { id: 1, name: '跑腿版' },
                { id: 2, name: '司机版' },
                { id: 3, name: '商家版-超市' },
                { id: 4, name: '商家版-叫餐' },
            ],
            provArr: [],
            cityArr: [],
            districtArr: [],
            editRules: {  // 表单验证规则
                type: [
                    { required: true, message: '请选择显示版', trigger: 'change' }
                ],
                pid: [
                    { required: true, message: '请选择省', trigger: 'blur' }
                ],
            },
            // 自定义文件上传(这里使用把选择的文件转成blob演示)
            file_picker_callback: (callback, value, meta) => {
                let input = document.createElement('input');
                input.setAttribute('type', 'file');
                // 设定文件可选类型
                if (meta.filetype === 'image') {
                    input.setAttribute('accept', 'image/*');
                } else if (meta.filetype === 'media') {
                    input.setAttribute('accept', 'video/*');
                }
                input.onchange = () => {
                    let file = input.files[0];
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        let blob = new Blob([e.target.result], { type: file.type });
                        callback(URL.createObjectURL(blob));
                    };
                    reader.readAsArrayBuffer(file);
                }
                input.click();
            }
        }
    },
    computed: {
        ...mapGetters(["permission"]),

        editContent() {
            return {
                menubar: false,
                // file_picker_callback: this.file_picker_callback,
                skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' : '/tinymce/skins/ui/oxide',
                content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' : '/tinymce/skins/content/default/content.css'
            };
        }
    },
    created() {
        this.$http.get('/common/province_list').then(res => {
            var data = JSON.parse(res.data)
            this.provArr = data
        })

    },
    mounted() {
    },
    methods: {


        /**
         *选择省
         **/
        handleChangeProv(e) {
            /** 获取被选省份的pid**/
            let pid = ''
            this.provArr.forEach(function (item) {
                if (item.pid == e) {
                    pid = item.pid
                }
            })
            /** 根据被选省份的pid获取省市下面的市**/
            this.$http.post('/common/city_list', { pid: pid }).then(res => {
                let data = JSON.parse(res.data)
                this.cityArr = data
                /** 选择省份清空市县**/
                this.table.where.cid = ''
                this.table.where.aid = ''



            })
        },
        /**
         *选择市
         **/
        handleChangeCity(e) {
            if (e) {
                /** 获取被选市的cid**/
                let cid = ''
                this.cityArr.forEach(function (item) {
                    if (item.cid == e) {
                        cid = item.cid
                    }
                })
                /** 根据被选市的cid获取市下面的县**/
                this.$http.post('/common/area_list', { cid: cid }).then(res => {
                    let data = JSON.parse(res.data)
                    this.districtArr = data
                    /** 选择省份清空县**/
                    this.table.where.aid = ''
                })
            } else {
                this.table.where.pid = ''
                this.table.where.aid = ''
            }
        },

        /**
         *选择省
         **/
        handleChangeProv1(e) {
            /** 获取被选省份的pid**/
            let pid = ''
            this.provArr.forEach(function (item) {
                if (item.pid == e) {
                    pid = item.pid
                }
            })
            /** 根据被选省份的pid获取省市下面的市**/
            this.$http.post('/common/city_list', { pid: pid }).then(res => {
                let data = JSON.parse(res.data)
                this.cityArr = data
                /** 选择省份清空市县**/
                this.editForm.cid = ''
                this.editForm.aid = ''



            })
        },
        /**
         *选择市
         **/
        handleChangeCity1(e) {
            if (e) {
                /** 获取被选市的cid**/
                let cid = ''
                this.cityArr.forEach(function (item) {
                    if (item.cid == e) {
                        cid = item.cid
                    }
                })
                /** 根据被选市的cid获取市下面的县**/
                this.$http.post('/common/area_list', { cid: cid }).then(res => {
                    let data = JSON.parse(res.data)
                    this.districtArr = data
                    /** 选择省份清空县**/
                    this.editForm.aid = ''

                })
            } else {
                this.editForm.pid = ''
                this.editForm.aid = ''
            }
        },

        edit(row) {
            // this.editForm = Object.assign({}, row);
            this.$http.post('/notice/service_info', { id: row.id }).then(res => {
                if (res.data.code === 0) {
                    //console.log(res.data.data);
                    this.editForm = res.data.data;
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                this.$message.error(e.message);
            });
            this.showEdit = true;
        },
        /* 保存编辑 */
        save() {

            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    // //console.log(this.editForm)
                    const loading = this.$loading({ lock: true });
                    this.$http.post('/notice/service_eidt', { id: this.editForm.id, content: this.editForm.content, type: this.editForm.type, aid: this.editForm.aid }).then(res => {
                        loading.close();
                        if (res.data.code === 0) {
                            this.showEdit = false;
                            this.$message({ type: 'success', message: res.data.msg });
                            this.$refs.table.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        loading.close();
                        this.$message.error(e.message);
                    });
                } else {
                    return false;
                }
            });
        },
        /* 删除 */
        remove(row) {
            if (!row) {  // 批量删除
                if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
                let ids = this.choose.map(d => d.id);
                this.$confirm('确定要删除选中的通知吗?', '提示', { type: 'warning' }).then(() => {
                    const loading = this.$loading({ lock: true });
                    this.$http.post('/notice/service_delete', { id: ids }).then(res => {
                        loading.close();
                        if (res.data.code === 0) {
                            this.$message({ type: 'success', message: res.data.msg });
                            this.$refs.table.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        loading.close();
                        this.$message.error(e.message);
                    });
                }).catch(() => 0);
            } else {  // 单个删除
                const loading = this.$loading({ lock: true });
                this.$http.post('/notice/service_delete', { id: row.id }).then(res => {
                    loading.close();
                    if (res.data.code === 0) {
                        this.$message({ type: 'success', message: res.data.msg });
                        this.$refs.table.reload();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    loading.close();
                    this.$message.error(e.message);
                });
            }
        },

    }
}
</script>

<style scoped>
.ele-block>>>.el-upload,
.ele-block>>>.el-upload-dragger {
    width: 100%;
}
</style>